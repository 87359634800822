import { Swiper, SwiperProps, SwiperRef, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { AutoplayOptions } from 'swiper/types/modules/autoplay';
import 'swiper/css';
import 'swiper/css/pagination';
import { FC, ReactNode, useCallback, useRef, useState } from 'react';
import { NextPageIcon, PreviousPageIcon } from '@/theme/Icons';
import { Box, StyleProps } from '@chakra-ui/react';
import useMediaQuery from '@/hooks/useMediaQuery';
import { SerializedStyles } from '@emotion/react';
import { useAppSettings } from '@/context/AppSettings';

interface SlideProps extends SwiperProps {
  children: ReactNode[];
  containerStyle?: StyleProps;
  bulletClass?: string; // Custom class for all bullets
  bulletActiveClass?: string; // Custom class for active bullet
  bulletStyles?: SerializedStyles;
  showArrowNavigation?: boolean;
  enablePagination?: boolean;
  enableDraggable?: boolean;
  /**
   * Example of custom Navigation component (not styled)
   const CustomNav = ({
   onNext,
   onPrev
   }: {
   onNext: () => void;
   onPrev: () => void;
   }) => (
   <div>
   <button onClick={onPrev}>Previous</button>
   <button onClick={onNext}>Next</button>
   </div>
   );
   *
   */
  CustomNavigation?: FC<{ onNext: () => void; onPrev: () => void }>;
  autoPlay?: boolean;
  delayAutoPlay?: number;
  delayBetweenTransitionBanner?: number;
  sliderTestId: string;
}

const Slide: FC<SlideProps> = ({
  children,
  containerStyle,
  bulletClass = 'swiper-pagination-bullet',
  bulletActiveClass = 'swiper-pagination-bullet-active',
  bulletStyles,
  showArrowNavigation = true,
  enablePagination = true,
  enableDraggable = true,
  CustomNavigation,
  delayAutoPlay = 5000,
  autoPlay = true,
  delayBetweenTransitionBanner = 1500,
  sliderTestId = 'slider',
  ...props
}) => {
  const isMobile = useMediaQuery('(max-width: md)');
  const swiperRef = useRef<SwiperRef>(null);
  const [positionBullets, setPositionBullets] = useState<{
    first: { x: number | undefined; y: number | undefined };
    last: { x: number | undefined; y: number | undefined };
  }>({ first: { x: 0, y: 0 }, last: { x: 0, y: 0 } });
  const { isPlayingGame } = useAppSettings();

  // Default custom navigation
  const DefaultCustomNavigation = useCallback(() => {
    const container = document?.querySelector('.custom-slider-container');
    // const containerPosition = container?.getBoundingClientRect();
    const arrowLeft = ((container as HTMLElement)?.offsetLeft || 0) - 30;
    const arrowRight =
      window.innerWidth -
      ((container as HTMLElement)?.offsetWidth || 0) -
      (container as HTMLElement)?.offsetLeft -
      30;

    const positionArrows = {
      prev: arrowLeft,
      next: arrowRight
    };

    // console.log({
    //   offsetleft: (container as HTMLElement)?.offsetLeft,
    //   offsetWidth: (container as HTMLElement)?.offsetWidth,
    //   window: window.innerWidth
    // });

    if (children.length <= 1) return null;
    return !isMobile ? (
      <Box>
        <PreviousPageIcon
          data-testid="slide-prev-button"
          sx={{
            position: 'absolute',
            left: window?.innerWidth <= 1368 ? '5px' : positionArrows.prev,
            top: '60%',
            transform: "translateY('-60%')",
            height: '30px',
            zIndex: 10,
            cursor: 'pointer',
            filter: 'drop-shadow(0px 0px 3px rgba(0 0 0 / .5))'
          }}
          onClick={() => swiperRef.current?.swiper.slidePrev()}
        />
        <NextPageIcon
          data-testid="slide-next-button"
          sx={{
            position: 'absolute',
            right: window?.innerWidth <= 1368 ? '5px' : positionArrows.next,
            top: '60%',
            transform: "translateY('-60%')",
            height: '30px',
            zIndex: 10,
            cursor: 'pointer',
            filter: 'drop-shadow(0px 0px 3px rgba(0 0 0 / .5))'
          }}
          onClick={() => swiperRef.current?.swiper.slideNext()}
        />
      </Box>
    ) : (
      <Box>
        <Box
          as="span"
          borderRadius={'50%'}
          background="rgba(255, 255, 255, .6)"
          width="24px"
          height={'24px'}
          position="absolute"
          left={(positionBullets.first.x || 0) - 35 + 'px'}
          top={(positionBullets.first.y || 0) - 8 + 'px'}
          zIndex={10}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <PreviousPageIcon
            data-testid="slide-prev-mobile-button"
            sx={{
              height: '10px',
              cursor: 'pointer'
            }}
            onClick={() => swiperRef.current?.swiper.slidePrev()}
          />
        </Box>
        <Box
          as="span"
          borderRadius={'50%'}
          background="rgba(255, 255, 255, .6)"
          width="24px"
          height={'24px'}
          position="absolute"
          left={(positionBullets.last.x || 0) + 20 + 'px'}
          top={(positionBullets.last.y || 0) - 8 + 'px'}
          zIndex={10}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <NextPageIcon
            data-testid="slide-next-mobile-button"
            sx={{
              height: '10px',
              cursor: 'pointer'
            }}
            onClick={() => swiperRef.current?.swiper.slideNext()}
          />
        </Box>
      </Box>
    );
  }, [children, positionBullets, isMobile]);

  const handleNavigationComputation = () => {
    // Make some computation to get position of the first and last pagination bullet and set these values on the navigation arrow of mobile version
    const nodeList = document.querySelector('.swiper-pagination')?.children;
    setPositionBullets({
      first: {
        x: nodeList?.[0].getBoundingClientRect().x,
        y: nodeList?.[0].getBoundingClientRect().y
      },
      last: {
        x: nodeList?.[nodeList?.length - 1].getBoundingClientRect().x,
        y: nodeList?.[nodeList?.length - 1].getBoundingClientRect().y
      }
    });
  };

  const autoPlayOptions = (): AutoplayOptions | boolean => {
    if (!autoPlay || isPlayingGame) return false;
    return {
      pauseOnMouseEnter: true,
      delay: delayAutoPlay,
      disableOnInteraction: true
    };
  };

  return (
    <Box sx={{ ...containerStyle }} data-testid={sliderTestId}>
      <Swiper
        ref={swiperRef}
        observer={true}
        observeParents={true}
        data-testid={sliderTestId + '-swiper'}
        modules={[Navigation, Pagination, Scrollbar, Autoplay]}
        slidesPerView={1}
        navigation={false}
        pagination={{
          enabled: enablePagination,
          clickable: true,
          bulletClass,
          bulletActiveClass
        }}
        scrollbar={{ draggable: enableDraggable }}
        autoplay={autoPlayOptions()}
        speed={delayBetweenTransitionBanner}
        onInit={() => {
          // hide horizontal scrollbar
          const scroll = document.querySelector('.swiper-scrollbar-horizontal');
          scroll
            ? ((scroll as HTMLElement).style.visibility = 'hidden')
            : undefined;

          handleNavigationComputation();
        }}
        onResize={() => {
          handleNavigationComputation();
        }}
        {...props}
      >
        {children.map((child, index) => (
          <SwiperSlide itemRef="" key={index}>
            {child}
          </SwiperSlide>
        ))}
        {showArrowNavigation ? (
          CustomNavigation ? (
            <CustomNavigation
              onNext={() => swiperRef.current?.swiper.slideNext()}
              onPrev={() => swiperRef.current?.swiper.slidePrev()}
            />
          ) : (
            <DefaultCustomNavigation />
          )
        ) : null}

        {/* Apply the bullet and navigation styles from props */}
        <style>{bulletStyles?.styles}</style>
      </Swiper>
    </Box>
  );
};
export default Slide;
